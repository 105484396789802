export default {
  data() {
    return {
      logRules: {
        login: [
          { required: true, message: 'Логин обязательный', trigger: 'blur' },
          {
            min: 4,
            message: 'Логин слишком короткий',
            trigger: 'blur',
          },
        ],
        password: [
          { required: true, message: 'Пароль обязательный', trigger: 'blur' },
          {
            min: 4,
            max: 18,
            message: 'Пароль слишком короткий',
            trigger: 'blur',
          },
        ],
      },
      telRules: {
        phone: [
          {
            required: true,
            message: 'Поле обязательно для заполнения',
            trigger: 'change',
          },
          {
            min: 18,
            max: 18,
            message: 'Некорректный номер',
            trigger: 'blur',
          },
        ],
        password: [
          { required: true, message: 'Пароль обязательный', trigger: 'blur' },
          {
            min: 4,
            max: 18,
            message: 'Пароль слишком короткий',
            trigger: 'blur',
          },
        ],
      },
    }
  },
}
