import IqButton from '@/views/ui/components/buttons/IqButton'
import IqLogRadio from '@/views/login/components/IqLogRadio'

import { FETCH_AUTH, GET_IS_AUTH_LOADING } from '@/store/actions'
import { mapGetters } from 'vuex'
import entranceRulesMixin from '@/views/login/data/entranceRulesMixin'
export default {
  components: { IqLogRadio, IqButton },
  mixins: [entranceRulesMixin],
  data() {
    return {
      formData: {
        phone: '',
        login: '',
        password: '',
      },
      formOption: true,
    }
  },
  computed: {
    ...mapGetters({ isLoading: GET_IS_AUTH_LOADING }),
    currentRules() {
      return this.formOption ? this.telRules : this.logRules
    },
  },
  created() {
    this.$nextTick(() => this.$refs.phone.clearValidate())
  },
  methods: {
    answer(val) {
      this.formOption = !this.formOption
      this.$nextTick(() => {
        this.$refs.password.clearValidate()

        if (!this.formOptions) {
          this.$refs.login.clearValidate()
        } else {
          this.$refs.phone.clearValidate()
        }
      })
    },
    handleLogin(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let data = {
            login: !this.formOption
              ? this.formData.login
              : this.formData.phone.replace(/[\s()-]/g, ''),
            password: this.formData.password,
          }

          this.$store.dispatch(FETCH_AUTH, { data, isPhone: this.formOption })
          // this.$refs[formName].resetFields()
        } else {
          return false
        }
      })
    },
    forgotPassword() {
      this.$emit('forgotten-password')
    },
  },
}
